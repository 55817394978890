<template>
  <div class="homeCare-container">
    <banner :banner-list="bannerList" />
    <div class="homeCare-nav">
      <ul class="homeCare-nav-content van-hairline--bottom">
        <router-link active-class="active" tag="li" to="/homeCare/introduce">系统介绍</router-link>
        <router-link active-class="active" tag="li" to="/homeCare/offer">系统报价</router-link>
      </ul>
    </div>
    <div class="homeCare-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/banner/Banner.vue';
  export default {
    data() {
      return {
        bannerList: {
          src: '/global/homecare/banner.png',
          list: [
            {
              icon: 'JG',
              title: '数据信息化、智能监管',
              content: '老人档案、服务商管理、员工管理、服务工单管理、政府采购管理等系统实现数据信息化，以系统数据为依据更好地监管、分配居家服务资源，提高居家养老服务质量。'
            },
            {
              icon: 'YJ',
              title: '智能设备监测健康安全数据',
              content: '通过使用配套居家智能设备，实现24小时监测老人健康数据和定位安全情况；数据实时自动上传，系统接收到异常情况及时发出警报，促使老人及时就医，为医生诊断提供数据依据。'
            },
            {
              icon: 'SJ',
              title: '数据智能统计分析',
              content: '服务工单、会员交易消费等运营数据统计分析可视化，为运营服务查证监管做支撑；老人档案信息、智能设备健康数据统计分析，为老人健康保障做依据。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    }
  }
</script>

<style lang="scss" scoped>
  .homeCare-container {
    .homeCare-nav {
      margin-top: 2.84rem;
      .homeCare-nav-content {
        display: flex;
        width: 100%;
        > li {
          height: .44rem;
          width: 50%;
          text-align: center;
          line-height: .44rem;
          font-size: .18rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          position: relative;
        }
        .active {
          color: #00ACF6;
          &::after {
            content: '';
            width: .32rem;
            height: .02rem;
            background-color: #00ACF6;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -.16rem;
          }
        }
      }
    }
  }
</style>